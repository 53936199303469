
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { IMortgageQuote, ISimulatorFormData } from "@/models/Simulator";
import SimulatorResults from "@/components/tools/mortgage-simulator/SimulatorResults.vue";

@Component({
  components: { SimulatorResults },
})
export default class SimulatorResultsMobile extends Vue {
  @Prop({ type: Object, required: true })
  protected mortgageQuote!: IMortgageQuote;

  @Prop({ type: [Object, Function], required: true })
  protected formData!: ISimulatorFormData;

  protected monthlyQuota = 0;

  @Emit("open")
  clicked() {}
}
