
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  IAppraiserValuationBannerTypeData,
  IAppraiserValuationBannerTypeList,
} from "@/models/Appraiser";
import { APPRAISER_VALUATION_BANNER_TYPES } from "@/constants/tools";

@Component
export default class AppraiserValuationBanner extends Vue {
  @Prop({ type: String, default: null })
  protected leadCreatedAt?: string;

  @Prop({ type: Object, required: true })
  protected bannerType!: IAppraiserValuationBannerTypeData;

  protected bannerTypes: IAppraiserValuationBannerTypeList =
    APPRAISER_VALUATION_BANNER_TYPES;

  protected MAX_DAYS_TO_SHOW_ON_LEAD_CREATED = 7;

  protected showBanner = false;
  protected showSuccessBanner = false;
  protected bannerDataTracking?: string;

  beforeMount() {
    this.assertShowBanner();
  }

  protected assertShowBanner(): void {
    if (this.leadCreatedAt) {
      this.showSuccessBanner = this.assertShowSuccessBanner(this.leadCreatedAt);
      if (this.showSuccessBanner) {
        this.showBanner = true;
        this.bannerType.type = this.bannerTypes.SUCCESS;
      } else this.showBanner = false;
    } else this.showBanner = true;
  }

  protected assertShowSuccessBanner(leadCreatedAt: string): boolean {
    const today = new Date(Date.now());
    const leadCreatedMsDiff =
      today.getTime() - new Date(leadCreatedAt).getTime();
    const leadCreatedDaysDiff = Math.floor(
      leadCreatedMsDiff / 1000 / 60 / 60 / 24
    );
    return leadCreatedDaysDiff <= this.MAX_DAYS_TO_SHOW_ON_LEAD_CREATED;
  }

  protected get bannerLabel(): string {
    switch (this.bannerType.type) {
      case this.bannerTypes.IBUYER:
        this.bannerDataTracking = "i-buyer";
        return this.$tc("valuationBanner.iBuyer", 2, {
          price: this.bannerType.priceLabel,
        });
      case this.bannerTypes.BUY_TO_RENT:
        this.bannerDataTracking = "buy-to-rent";
        return this.$tc("valuationBanner.buyToRent", 2, {
          price: this.bannerType.priceLabel,
        });
      case this.bannerTypes.SUCCESS:
        return this.$tc("valuationBanner.success", 2);
      default:
        this.bannerDataTracking = "generic";
        return this.$tc("valuationBanner.generic", 2);
    }
  }

  @Emit("createLead") createLead() {}
}
