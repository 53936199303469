
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import AppraiserFormWrapper from "@/components/tools/appraiser-simulator/AppraiserFormWrapper.vue";
import AppraiserEmptyState from "@/components/tools/appraiser-simulator/AppraiserEmptyState.vue";
import AppraiserValuationsTable from "@/components/tools/appraiser-simulator/AppraiserValuationsTable.vue";
import AppraiserValuationWrapper from "@/components/tools/appraiser-simulator/AppraiserValuationWrapper.vue";
import { IAppraiserData } from "@/models/Appraiser";
import HiboLoader from "@/components/ui/HiboLoader.vue";

@Component({
  computed: {
    ...mapGetters("appraiser", {
      valuations: "getValuations",
      currentValuation: "getCurrentValuation",
      pagination: "getPagination",
      loading: "appraiserLoading",
    }),
  },
  components: {
    HiboLoader,
    AppraiserValuationWrapper,
    AppraiserValuationsTable,
    AppraiserEmptyState,
    AppraiserFormWrapper,
  },
})
export default class AppraiserSimulator extends Vue {
  protected valuations!: IAppraiserData[];
  protected currentValuation?: IAppraiserData | null;
  protected valuationModal = false;

  protected beforeDestroy() {
    if (this.currentValuation)
      this.$store.dispatch("appraiser/clearCurrentValuation");
  }

  protected openModal(): void {
    this.valuationModal = true;
  }

  protected closeModal(): void {
    this.valuationModal = false;
  }

  async mounted() {
    if (!this.valuations.length)
      await this.$store.dispatch("appraiser/retrieveValuations");
  }
}
