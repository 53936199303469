
import { Component, Vue } from "vue-property-decorator";
import { TOOL_TABS } from "@/constants/tools";
import { mapGetters } from "vuex";
import { IAppraiserData } from "@/models/Appraiser";
import { ITab } from "@/models/Global";
import ToolsHeader from "@/components/tools/ToolsHeader.vue";
import MortgageSimulator from "@/components/tools/mortgage-simulator/MortgageSimulator.vue";
import AppraiserValuationWrapper from "@/components/tools/appraiser-simulator/AppraiserValuationWrapper.vue";
import AppraiserSimulator from "@/components/tools/appraiser-simulator/AppraiserSimulator.vue";

@Component({
  computed: {
    ...mapGetters("appraiser", {
      appraiserEnabled: "getEnabled",
      currentValuation: "getCurrentValuation",
    }),
  },
  components: {
    AppraiserSimulator,
    AppraiserValuationWrapper,
    ToolsHeader,
    MortgageSimulator,
  },
})
export default class Tools extends Vue {
  protected toolTabs: ITab[] = TOOL_TABS;
  protected currentValuation?: IAppraiserData | null;
  protected appraiserEnabled?: boolean;

  async beforeMount() {
    await this.assertShowAppraiser();
  }

  protected assertShowAppraiser(): void {
    if (!this.appraiserEnabled) {
      if (this.$route.name === "appraiserSimulator")
        this.$router.push({ name: "mortgageSimulator" });
      else {
        const appraiserTabIndex = this.toolTabs.findIndex(
          (tab) => tab.name === "appraiserSimulator"
        );
        this.toolTabs[appraiserTabIndex].available = false;
      }
    }
  }
  protected get currentComponent(): string {
    this.toolTabs.map((tab) => {
      tab.selected = this.$route.name === tab.routeName;
    });
    const tab = this.toolTabs.find((tab) => {
      return tab.selected && tab.available;
    });
    return tab?.component || this.toolTabs[0].component;
  }
}
