
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { TOOL_OPTIONS } from "@/constants/tools";
import { ISelectOption } from "@/models/Global";

@Component
export default class LeadFormDropdown extends Vue {
  @Prop({ type: String, default: "newLead" })
  protected labelKey?: string;

  @Prop({ type: Boolean, default: false })
  protected withDataTracking?: boolean;

  protected options: ISelectOption[] = TOOL_OPTIONS;

  protected get isFlapPlaced() {
    return this.$route.name === "mortgageSimulator";
  }

  @Emit("click")
  clicked(option: ISelectOption) {
    return option;
  }
}
