
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { IAppraiserData } from "@/models/Appraiser";
import dayjs from "@/plugins/time";

@Component
export default class AppraiserValuationsTableBody extends Vue {
  @Prop({ type: Array, required: true })
  protected valuations!: IAppraiserData[];

  protected formattedDate(unformattedDate: string): string {
    const dateFormat: string = this.$t("dateFormatSimple").toString();
    return dayjs(unformattedDate).format(dateFormat);
  }

  protected openValuation(valuation: IAppraiserData) {
    this.$store.dispatch("appraiser/setCurrentValuation", valuation);
  }

  @Emit("updateValuation")
  updateValuation() {}

  @Emit("deleteValuation")
  deleteValuation() {}
}
