
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import {
  IMortgageQuote,
  ISimulatorFormData,
  IChartData,
} from "@/models/Simulator";
import HorzBarChart from "@/components/charts/HorzBarChart.vue";
import Money from "@/models/Money";
import VueI18n from "vue-i18n";

@Component({
  components: { HorzBarChart },
})
export default class SimulatorResults extends Vue {
  @Prop({ type: Object, required: true })
  protected mortgageQuote!: IMortgageQuote;

  @Prop({ type: [Object, Function], required: true })
  protected formData!: ISimulatorFormData;

  get firstChartData(): IChartData[] {
    return [
      {
        price: +this.mortgageQuote.propertyTaxAmount,
        color: "#FF5A54",
      },
      {
        price: +this.mortgageQuote.propertyPrice,
        color: "#FFB1A9",
      },
    ];
  }
  get secondChartData(): IChartData[] {
    return [
      {
        price: +this.mortgageQuote.savings,
        color: "#06A8FF",
      },
      {
        price: +this.mortgageQuote.mortgageAmount,
        color: "#76CFFF",
      },
      {
        price: +this.mortgageQuote.mortgageTaxAmount,
        color: "#ACE2FF",
      },
    ];
  }
  get totalPurchasePrice(): string | number {
    const val =
      (+this.mortgageQuote.propertyPrice +
        +this.mortgageQuote.propertyTaxAmount) /
      100;
    const money = new Money(+val);
    return money.formatNoCurrency();
  }
  moneyFormatted(input: number) {
    const val = input / 100;
    const money = new Money(val);
    return money.formatNoCurrency();
  }

  protected get monthlyQuota(): string {
    const quota = this.moneyFormatted(+this.mortgageQuote.monthlyQuota) + "€";
    return quota;
  }

  get interestType(): VueI18n.TranslateResult {
    return this.formData.rateType === 2
      ? this.$t("adjustableInterest")
      : this.$t("fixedInterest");
  }

  get isAdjustableInterest(): boolean {
    return this.formData.rateType === 2;
  }

  get euribor(): number {
    return this.formData.euribor;
  }

  @Watch("monthlyQuota", { immediate: true })
  handleQuota(val: string) {
    this.$emit("quota", val);
  }

  @Emit("open")
  emitOpen() {}
}
