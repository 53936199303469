
import { Component, Prop, Vue } from "vue-property-decorator";
import { IAppraiserData } from "@/models/Appraiser";
import { IPagination } from "@/models/Global";
import AppraiserValuationsWrapper from "@/components/tools/appraiser-simulator/AppraiserValuationsWrapper.vue";

@Component({
  components: { AppraiserValuationsWrapper },
})
export default class AppraiserValuationsTable extends Vue {
  @Prop({ type: Array, required: true })
  protected valuations!: IAppraiserData[];

  @Prop({ type: Boolean, required: true })
  protected loading!: boolean;

  @Prop({ type: Object, required: true })
  protected pagination!: IPagination;
}
