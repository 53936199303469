
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { IRadioItems } from "@/models/Global";
import { ISimulatorFormData } from "@/models/Simulator";
import { ISelectOption } from "@/models/Global";
import HiboGoogleAddressInput from "@/components/ui/HiboGoogleAddressInput.vue";
import HiboCurrencyInput from "@/components/ui/HiboCurrencyInput.vue";

@Component({
  components: { HiboGoogleAddressInput, HiboCurrencyInput },
})
export default class SimulatorCalculator extends Vue {
  @Prop({ type: [Object, Function], required: true })
  protected formData!: ISimulatorFormData;

  radioItems: IRadioItems[] = [
    {
      label: "Fijo",
      value: 1,
    },
    {
      label: " Variable",
      value: 2,
    },
  ];
  purchasePurposeOptions: ISelectOption[] = [
    {
      label: "firstResidence",
      value: "first_property",
    },
    {
      label: "secondResidence",
      value: "second_property",
    },
    {
      label: "changeOfResidence",
      value: "change_property",
    },
    {
      label: "investment",
      value: "investment",
    },
  ];
  @Emit("input")
  handleChange() {
    return setTimeout(() => this.formData, 500);
  }
}
