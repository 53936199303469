
import { Component, Prop, Vue } from "vue-property-decorator";
import { IAppraiserData } from "@/models/Appraiser";

@Component
export default class AppraiserValuationCharacteristics extends Vue {
  @Prop({ type: Object, required: true })
  protected valuation!: IAppraiserData;

  protected get hasAdditionalInfo(): boolean {
    return (
      this.valuation.extraData.hasExterior ||
      this.valuation.extraData.hasGarden ||
      this.valuation.extraData.hasCommunalPool ||
      this.valuation.extraData.hasPrivatePool ||
      this.valuation.extraData.hasGarage ||
      this.valuation.extraData.hasSportsArea ||
      this.valuation.extraData.hasTerrace ||
      this.valuation.extraData.hasBalcony ||
      this.valuation.extraData.hasYard ||
      this.valuation.extraData.hasStorageRoom ||
      this.valuation.extraData.hasAirConditioning ||
      this.valuation.extraData.hasElevator ||
      this.valuation.extraData.hasAluminosisOrOther ||
      false
    );
  }
}
