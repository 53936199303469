
import { Component, Prop, Vue } from "vue-property-decorator";
import { IAppraiserData } from "@/models/Appraiser";

@Component
export default class AppraiserValuationTitle extends Vue {
  @Prop({ type: Object, required: true })
  protected valuation!: IAppraiserData;

  protected get neighborhoodAndCityLabel() {
    return this.valuation.neighborhood
      ? this.valuation.neighborhood + ", " + this.valuation.city
      : this.valuation.city;
  }
}
