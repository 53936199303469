
import { Component, Vue, Prop } from "vue-property-decorator";
import { inputOnlyNumbers } from "@/services/FormService";
import Money from "@/models/Money";

@Component
export default class HiboCurrencyInput extends Vue {
  @Prop({ type: String, default: "" })
  protected rightText?: string;

  @Prop({ type: String, default: "text" })
  protected type?: string;

  @Prop({ type: String, default: null })
  protected placeholder?: string;

  @Prop({ type: [String, Number], default: null })
  protected value?: string | number;

  @Prop({ type: String, default: "default-input" })
  protected label?: string;

  @Prop({ type: Boolean, default: false })
  protected autofocus?: boolean;

  @Prop({ type: String, default: "off" })
  protected autocomplete?: string;

  @Prop({ type: String, default: null })
  protected name?: string;

  @Prop({ type: String, default: null })
  protected id?: string;

  @Prop({ type: String, default: null })
  protected errorText?: string;

  get displayValue() {
    if (this.value === null || this.value === undefined) return null;
    const money = new Money(this.value);
    return money.formatNoCurrency();
  }

  set displayValue(newValue) {
    let num = newValue;
    if (typeof num === "string") num = num.replace(/\./g, "");
    this.$emit("input", num || 0);
  }

  validateInputChar(event: any) {
    if (!inputOnlyNumbers(event)) event.preventDefault();
  }
}
