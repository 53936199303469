
import { Vue, Prop, Component } from "vue-property-decorator";
import { ISelectOption, ITab } from "@/models/Global";
import LeadFormDropdown from "@/components/common/LeadFormDropdown.vue";
import AppraiserFormWrapper from "@/components/tools/appraiser-simulator/AppraiserFormWrapper.vue";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("appraiser", {
      appraiserEnabled: "getEnabled",
    }),
  },
  components: { AppraiserFormWrapper, LeadFormDropdown },
})
export default class ToolsHeader extends Vue {
  @Prop({ type: Array, default: null })
  protected toolTabs?: ITab[];

  protected valuationModal = false;
  protected optionSelected = "";

  protected handleTabChanged(tabName: string) {
    const tab = this.toolTabs?.find((tab) => {
      return tab.name === tabName;
    });
    if (tab == null || this.$route.name === tab.name) return;
    this.$router.push({ name: tab.routeName });
  }

  protected handleOptionSelected(option: ISelectOption) {
    switch (option.value) {
      case "appraiserSimulator":
        this.valuationModal = true;
        this.optionSelected = option.value;
        break;
      case "mortgageSimulator":
        if (this.$route.name !== "mortgageSimulator")
          this.$router.push({ name: "mortgageSimulator" });
        this.optionSelected = option.value;
        break;
      default:
        break;
    }
  }
}
