
import { Component, Vue, Prop } from "vue-property-decorator";

export interface IHorzBarChartData {
  price: number;
  color: string;
}

@Component
export default class HorzBarChart extends Vue {
  @Prop({ type: Array, required: true })
  protected chartData!: IHorzBarChartData[];

  get total() {
    const prices = this.chartData.map((item) => item.price);
    return prices.reduce(
      (accumulated: number, current: number) => accumulated + current
    );
  }

  get BarChartData() {
    const data = this.chartData.map((item) => ({
      percent: Math.round((item.price / this.total) * 100),
      color: item.color,
    }));
    return data;
  }
}
