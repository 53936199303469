
import { Component, Prop, Vue } from "vue-property-decorator";
import { LIST_TH } from "@/constants/tools";
import { IPagination, ITh } from "@/models/Global";
import { IAppraiserData } from "@/models/Appraiser";
import CustomTableWrapper from "@/components/common/CustomTableWrapper.vue";
import AppraiserValuationsTableBody from "@/components/tools/appraiser-simulator/AppraiserValuationsTableBody.vue";

@Component({
  components: { CustomTableWrapper, AppraiserValuationsTableBody },
})
export default class AppraiserValuationsWrapper extends Vue {
  @Prop({ type: Array, required: true })
  protected valuations!: IAppraiserData[];

  @Prop({ type: Boolean, required: true })
  protected loading!: boolean;

  @Prop({ type: Object, required: true })
  protected pagination!: IPagination;

  protected tableThConfig: ITh[] = LIST_TH;

  protected paginateValuations(dir: string) {
    let page = this.pagination.page || 1;
    if (dir === "next" && page < (this.pagination.totalPages || 0)) page++;
    else if (dir === "prev" && page > 1) page--;
    this.filterValuations({ page: page });
  }

  protected filterValuations(pagination: IPagination | null = { page: 1 }) {
    this.$store.dispatch("appraiser/retrieveValuations", {
      pagination: pagination,
      filters: [],
    });
  }

  protected updateValuation(valuationId: number) {
    this.$store.dispatch("appraiser/updateValuation", valuationId);
  }

  protected deleteValuation(valuationId: number) {
    this.$store.dispatch("appraiser/deleteValuation", valuationId);
  }
}
