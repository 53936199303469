
import { Component, Prop, Vue } from "vue-property-decorator";
import { IAppraiserData } from "@/models/Appraiser";
import AppraiserValuationHeader from "@/components/tools/appraiser-simulator/AppraiserValuationHeader.vue";
import AppraiserValuationBanner from "@/components/tools/appraiser-simulator/AppraiserValuationBanner.vue";
import AppraiserValuationTitle from "@/components/tools/appraiser-simulator/AppraiserValuationTitle.vue";
import AppraiserValuationCadastralRef from "@/components/tools/appraiser-simulator/AppraiserValuationCadastralRef.vue";
import AppraiserValuationPrice from "@/components/tools/appraiser-simulator/AppraiserValuationPrice.vue";
import AppraiserValuationCharacteristics from "@/components/tools/appraiser-simulator/AppraiserValuationCharacteristics.vue";
import AppraiserValuationFooter from "@/components/tools/appraiser-simulator/AppraiserValuationFooter.vue";
import HiboLoader from "@/components/ui/HiboLoader.vue";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("appraiser", {
      loading: "appraiserLoading",
    }),
  },
  components: {
    AppraiserValuationHeader,
    AppraiserValuationBanner,
    AppraiserValuationTitle,
    AppraiserValuationCadastralRef,
    AppraiserValuationPrice,
    AppraiserValuationCharacteristics,
    AppraiserValuationFooter,
    HiboLoader,
  },
})
export default class AppraiserValuationWrapper extends Vue {
  @Prop({ type: Object, required: true })
  protected valuation!: IAppraiserData;

  protected navigateBack() {
    this.$store.dispatch("appraiser/clearCurrentValuation");
  }

  protected updateValuation() {
    this.$store.dispatch("appraiser/updateValuation", this.valuation.id);
  }

  protected deleteValuation() {
    this.$store.dispatch("appraiser/deleteValuation", this.valuation.id);
    this.$store.dispatch("appraiser/clearCurrentValuation");
  }

  protected createCrossSellLead() {
    this.$store.dispatch("appraiser/createCrossSellLead", this.valuation.id);
  }
}
