
import { Component, Vue, Watch } from "vue-property-decorator";
import LeadFormsWrapper from "@/components/common/LeadFormsWrapper.vue";
import { ISimulatorFormData, IMortgageQuote } from "@/models/Simulator";
import { IApiResponse } from "@/models/Global";
import { BUSINESS } from "@/constants";
import SimulatorResultsMobile from "@/components/tools/mortgage-simulator/SimulatorResultsMobile.vue";
import SimulatorResults from "@/components/tools/mortgage-simulator/SimulatorResults.vue";
import SimulatorHeader from "@/components/tools/mortgage-simulator/SimulatorHeader.vue";
import SimulatorCalculator from "@/components/tools/mortgage-simulator/SimulatorCalculator.vue";

@Component({
  components: {
    SimulatorHeader,
    SimulatorCalculator,
    SimulatorResults,
    SimulatorResultsMobile,
    LeadFormsWrapper,
  },
})
export default class MortgageSimulator extends Vue {
  private readonly FIXED_INTEREST_TYPE = 1;
  private readonly FIXED_INTEREST_RATE = 3;
  private readonly ADJUSTABLE_INTEREST_TYPE = 2;
  private readonly ADJUSTABLE_INTEREST_RATE = 0.7;

  protected curr_business = BUSINESS.mortgages;
  protected timer = 0;
  protected clientModal = false;
  protected formData: ISimulatorFormData = {
    locationId: 3670,
    purchasePrice: 200000,
    savings: 0,
    term: 30,
    monthlyIncomes: 0,
    monthlyExpenses: 0,
    rate: this.FIXED_INTEREST_RATE,
    rateType: this.FIXED_INTEREST_TYPE,
    purchasePurpose: "first_property",
    euribor: 0,
  };
  protected mortgageQuote: IMortgageQuote = {
    monthlyQuota: 39700,
    mortgageAmount: 11050000,
    buyingPrice: 10000000,
    propertyPrice: 11000000,
    propertyTaxAmount: 1050000,
    totalAmount: 14308800,
    savings: 0,
    mortgageTaxAmount: 3258800,
  };

  mounted() {
    this.getEuribor();
    this.getQuota();
  }

  protected rate(): number {
    if (+this.formData.rateType === this.FIXED_INTEREST_TYPE)
      return (this.formData.rate = this.FIXED_INTEREST_RATE);
    else if (+this.formData.rateType === this.ADJUSTABLE_INTEREST_TYPE)
      return (this.formData.rate = this.ADJUSTABLE_INTEREST_RATE);
    else return (this.formData.rate = this.FIXED_INTEREST_RATE);
  }

  protected getRateValue(): number {
    if (this.formData.rateType === this.ADJUSTABLE_INTEREST_TYPE) {
      return this.ADJUSTABLE_INTEREST_RATE + this.formData.euribor;
    }
    return this.FIXED_INTEREST_RATE;
  }

  protected async getEuribor(): Promise<void> {
    const result: IApiResponse = await this.$store.dispatch(
      "mortgages/getEuribor"
    );
    if (result.res) {
      this.formData.euribor = result.res.value;
    }
  }

  protected async getQuota(): Promise<IMortgageQuote> {
    const request: ISimulatorFormData = {
      locationId: this.formData.locationId,
      purchasePrice: +this.formData.purchasePrice * 100,
      purchasePurpose: this.formData.purchasePurpose,
      term: +this.formData.term,
      savings: this.formData.savings * 100,
      rate: this.getRateValue(),
      rateType: this.formData.rateType,
      monthlyIncomes: this.formData.monthlyExpenses,
      monthlyExpenses: this.formData.monthlyExpenses,
      euribor: this.formData.euribor,
    };

    const result: IApiResponse = await this.$store.dispatch(
      "mortgages/getMortgageQuota",
      request
    );
    if (result.res) {
      this.mortgageQuote = result.res;
      return this.mortgageQuote;
    } else
      return {
        monthlyQuota: 39700,
        mortgageAmount: 11050000,
        buyingPrice: 10000000,
        propertyPrice: 11000000,
        propertyTaxAmount: 1050000,
        totalAmount: 14308800,
        savings: 0,
        mortgageTaxAmount: 3258800,
      };
  }

  @Watch("formData", { deep: true })
  onFormDataChange() {
    this.rate();
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => this.getQuota(), 1000);
  }
}
